import React, { lazy, Suspense } from "react";

import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";

import { CssBaseline, Container, Box, Typography } from "@mui/material";
import logo from "./assets/idunliv_logo.svg";

import useResponsive from "./hooks/useResponsive";

const DataTable = lazy(() => import("./components/DataTable"));

const App = () => {
  const isMobile = useResponsive("down", "md");

  return (
    <Suspense
      fallback={
        <Backdrop
          sx={{ color: "#fff", zIndex: theme => theme.zIndex.drawer + 1 }}
          open={true}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      }
    >
      <CssBaseline>
        <Container maxWidth="lg">
          <Box className="Content" sx={{ mt: 1, mb: 5 }}>
            <Box
              className="Header"
              sx={{
                display: "flex",
                flexDirection: "column",
                // alignItems: "center",
                mb: 2
              }}
            >
              <Box
                component="img"
                sx={{
                  height: 75,
                  width: 150
                }}
                alt="logo"
                src={logo}
              />
              <Typography
                variant="h4"
                color="#56bfde"
                sx={{ mt: isMobile ? 0 : 5 }}
              >
                Fondlista
              </Typography>
            </Box>
            <Box>
              <DataTable isMobile={isMobile} />
            </Box>
          </Box>
        </Container>
      </CssBaseline>
    </Suspense>
  );
};

export default App;
